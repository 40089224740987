import React from 'react';
import { Modal } from 'react-responsive-modal';

import Chip from '../../../components/chip/Chip';
import Button from '../../../components/Button.jsx';

import styles from './FlatDetails.module.scss';

const FlatDetails = (props) => {
	const { title, shortDescription, description, price, pictures, id, onDetailsClick, isModalOpen } =
		props;

	return (
		<div className={styles.container}>
			<div className={styles.price}>
				<Chip value={`${price || 0}€ pro Monat`} />
			</div>
			<h2 className={styles.title}>{title || ''}</h2>
			<p className={styles.description}>{shortDescription || ''}</p>
			<button className={styles['more-btn']} onClick={() => onDetailsClick()}>
				Mehr anzeigen
			</button>
			<Button text='Termin vereinbaren' url={`/contact?reason=${title}`} />
			<Modal open={isModalOpen} onClose={() => onDetailsClick()} animationDuration={50} center>
				<h3 className={styles['modal-title']}>{title || ''}</h3>
				<p>{shortDescription || ''}</p>
				<p>{description || ''}</p>

				<div>
					{pictures &&
						pictures.length > 0 &&
						pictures.map((picture, index) => (
							<img
								key={`picture-${index}`}
								src={`https://marstall-am-see.de:8443/apartments/${id}/${picture}`}
								alt='Flat'
								className={styles['flat-picture']}
							/>
						))}
				</div>
			</Modal>
		</div>
	);
};

export default FlatDetails;
