import React from 'react';

import './FilterItem.scss';

class FilterItem extends React.Component {
	render() {
		return (
			<div
				className='filter-item text-center'
				onClick={this.props.onClick !== undefined ? this.props.onClick : null}
			>
				{this.props.text}
			</div>
		);
	}
}

export default FilterItem;
