import React from 'react';

import './About.scss';

import Navbar from '../navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader.jsx';

class About extends React.Component {
	render() {
		return (
			<div className='about-page d-flex flex-column'>
				<Navbar />
				<PageHeader image='ABOUT_LANDING' title='Über uns' smallScreen={this.props.smallScreen} />
				<div className='about-part d-flex flex-column flex-lg-row'>
					<div
						className='about-image'
						style={{
							backgroundImage:
								this.props.smallScreen ? 'url(https://marstall-am-see.de:8443/siteImages/MOBILE/ABOUT_HISTORY)' : 'url(https://marstall-am-see.de:8443/siteImages/DESKTOP/ABOUT_HISTORY)'
						}}
					></div>
					<div className='about-text d-flex flex-column align-self-end'>
						<div className='about-text-title'>Geschichte</div>
						<div className='about-text-description'>
							<p>
								Das Marstallgebäude in Berg am Starnberger See ist ein historisches Gebäude, das
								König Ludwig II im Jahr 1866 als repräsentative Stallung für seine Pferde in
								unmittelbarere Nähe zum Schloss Berg errichten ließ. Im Erdgeschoss waren seine 18
								königlichen Rösser untergebracht, im ersten Obergeschoss wohnten die Bediensteten,
								im Dachgeschoss wurden Heu und Getreide gelagert.
							</p>
							Über die Nutzung des Marstallgebäudes nach dem Tod von König Ludwig II im Jahr 1886
							ist historisch wenig bekannt. Es gibt so gut wie keine erhaltenen Fotografien, Gemälde
							oder Dokumente. Dies mag daran liegen, dass es ein Nebengebäude war und damit nicht im
							Zentrum der Aufmerksamkeit lag, wie das Schloss Berg selbst, das der königlichen
							Familie vor allem als Sommerresidenz diente. Wahrscheinlich stand der Marstall über
							viele Jahrzehnte leer oder wurde als Lagerraum genutzt. So hinterließ die Zeit ihre
							Spuren und das Gebäude drohte zu verfallen.
						</div>
					</div>
				</div>
				<div className='about-quote text-center'>
					<div className='about-quote-text'>
						„Ich will, dass man nach meinem Tode sage:
						<br /> Ludwig hat nur danach gestrebt, seinem Volke der wahrhaft treueste Freund zu
						sein, und es ist ihm gelungen, sein Volk zu beglücken.“
					</div>
					<div className='about-quote-author'>Zitat Ludwig II</div>
				</div>
				<div className='about-part d-flex flex-column flex-lg-row'>
					<div
						className='about-image'
						style={{
							backgroundImage:
								this.props.smallScreen ? 'url(https://marstall-am-see.de:8443/siteImages/MOBILE/ABOUT_ENTSTEHUNG)' : 'url(https://marstall-am-see.de:8443/siteImages/DESKTOP/ABOUT_ENTSTEHUNG)'
						}}
					></div>
					<div className='about-text d-flex flex-column align-self-end'>
						<div className='about-text-title'>Entstehung</div>
						<div className='about-text-description'>
							<p>
								Ende der 1990er Jahre begann die Gemeinde Berg in Zusammenarbeit mit der Familie
								Höhne das Marstallgebäude originalgetreu zu restaurieren und gab ihm so den Glanz
								vergangener Zeit zurück.
							</p>
							So entstanden in den oberen Stockwerken Wohn- und Geschäftsräume, im Erdgeschoss ein
							großer Saal, der heute für Veranstaltungen verschiedener Art genutzt werden kann: von
							privaten Feiern und Hochzeiten über Ausstellungen und Konzerten bis hin zu Events und
							Tagungen für Firmen.
						</div>
					</div>
				</div>
				<div className='about-quote text-center'>
					<div className='about-quote-text'>
						„Oh, es ist notwendig, sich solche Paradiese zu schaffen, solch poetische Zufluchtsorte,
						wo man auf einige Zeit die schauderhafte Zeit, in der wir leben, vergessen kann.“
					</div>
					<div className='about-quote-author'>Zitat Ludwig II</div>
				</div>
				<div className='about-part d-flex flex-column flex-lg-row'>
					<div
						className='about-image'
						style={{
							backgroundImage:
								this.props.smallScreen ? 'url(https://marstall-am-see.de:8443/siteImages/MOBILE/ABOUT_ERICH)' : 'url(https://marstall-am-see.de:8443/siteImages/DESKTOP/ABOUT_ERICH)'
						}}
					></div>
					<div className='about-text d-flex flex-column'>
						<div className='about-text-description'>
							<p>
								… ein Gedanke, den man heute noch denken kann an einem Ort, für den das heute noch
								gilt.
							</p>
							<p>
								Schon zu Schulzeiten faszinierten mich „Gangsterlimousinen“, bis ich endlich selbst
								eine besaß. Später galt und gilt noch heute, meine Leidenschaft alten Peugeots.
								Durch Zufall wurde mir ein Borgward Isabella angeboten und ein Fiat 500 mit
								Flügeltüren. Wunderschöne Autos, alle haben eine Geschichte und von keinem möchte
								ich mich mehr trennen.
							</p>
							<p>
								Mit dem Marstall am See war es ähnlich. Ich bin im Landkreis Starnberg aufgewachsen,
								wusste um die Geschichte des Gebäudes, aber auch über den Zustand. Ich hatte eine
								Vision. So wurde der Marstall am See zu meiner Herausforderung: ein historisches
								Gemäuer mit Leidenschaft zum Leben erwecken.
							</p>
							„Das Leben feiern“ – gerne mit Ihnen! Kommen Sie mit Ihrer Idee vorbei.
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default About;
