import React from 'react';
import './ContactCheckbox.scss';

class ContactCheckbox extends React.Component {
	constructor(props) {
		super(props);
		this.click = this.click.bind(this);

		this.state = {
			selected: false
		};
	}

	render() {
		return (
			<div
				className='checkbox-image'
				onClick={this.click}
				style={{ border: this.props.errorShown ? '1px solid red' : 'none' }}
			>
				<img
					src='./checkboxSelected.svg'
					alt='Checkbox selected'
					style={{ opacity: this.state.selected ? 1 : 0 }}
				/>
				<img
					src='./checkboxUnselected.svg'
					alt='Checkbox unselected'
					style={{ opacity: this.state.selected ? 0 : 1 }}
				/>
			</div>
		);
	}

	click() {
		this.props.onChange(!this.state.selected);
		this.setState({
			selected: !this.state.selected
		});
	}
}

export default ContactCheckbox;
