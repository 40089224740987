import React from 'react';
import './Gallery.scss';

import Album from './Album.jsx';

class Gallery extends React.Component {
	constructor(props) {
		super(props);
		this.renderAlbums = this.renderAlbums.bind(this);
		this.fetchAlbums = this.fetchAlbums.bind(this);

		this.state = {
			albums : []
		}
	}

	render() {
		return(
			<React.Fragment>
				{(this.state.albums != null && this.state.albums.length > 0) ?
					<div className="gallery d-flex flex-column">
						<div className="d-flex flex-column">
							{this.renderAlbums()}
						</div>
					</div>
					: 
					<div className="empty-gallery d-flex flex-column justify-content-center align-items-center">
						Bilder folgen!
					</div>
				}
			</React.Fragment>
		);
	}

	componentDidMount() {
		this.setState({
			albums : this.fetchAlbums()
		});
	}

	renderAlbums() {
		var comp = this;
		if (this.state.albums != null) {
			var renderedItems = [];
			comp.state.albums.forEach(function(album, index){
				renderedItems.push(
					<Album
						smallScreen={comp.props.smallScreen}
						albumData={album}
						key={album.id}
					/>
				)
			});
			return renderedItems;
		}
	}

	fetchAlbums() {
		var url = "https://marstall-am-see.de:8443/gallery";
		fetch(url)
	      .then(res => res.json())
	      .then(
	        (result) => {
	        	this.setState({
	        		albums: result
	        	});
	        },
	        // Note: it's important to handle errors here
	        // instead of a catch() block so that we don't swallow
	        // exceptions from actual bugs in components.
	        (error) => {
	        	console.log(error);
	        }
	      )
	}
}

export default Gallery;