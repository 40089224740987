import React from 'react';

import { StyledDropdown } from './DropdownInput.styles';

const DropdownInput = ({ options = [], onChange, value }) => {
	return (
		<StyledDropdown
			searchable={false}
			options={options}
			values={[value]}
			dropdownGap={0}
			color='#1d0a96'
			onChange={(value) => {
				value[0] && onChange(value[0]);
			}}
			labelField='label'
			valueField='value'
		/>
	);
};

export default DropdownInput;
