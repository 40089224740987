import React, { useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Navbar from '../navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader.jsx';
import DropdownInput from '../components/dropdown-input/DropdownInput';
import ArtistsFilter from '../components/filter-buttons/FilterButtons';

import { Artwork } from './components';

import styles from './ArtSelling.module.scss';

const ArtSelling = (props) => {
	const [artsData, setArtsData] = useState([]);
	const [selectedArtist, setSelectedArtist] = useState({ value: '', label: 'Alle' });
	const [artistOptions, setArtistOptions] = useState([]);

	const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

	const getArtsData = async () => {
		try {
			const response = await fetch('https://marstall-am-see.de:8443/art');
			const data = await response.json();

			if (response.status === 200 && data) {
				let tempArtistOptions = new Map();

				for (const art of data) {
					if (art.artist && art.artist !== '') {
						tempArtistOptions.set(art.artist, { value: art.artist, label: art.artist });
					}
				}

				setArtsData(data);
				setArtistOptions([...tempArtistOptions.values()]);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getArtsData();
	}, []);

	const filteredArts = useMemo(() => {
		if (selectedArtist.value === '') return artsData;

		return (artsData || []).filter((art) => selectedArtist.value === art.artist);
	}, [selectedArtist, artsData]);

	return (
		<>
			<Navbar />
			<PageHeader
				image='ART_LANDING'
				title='Kunstverkauf – Kunstgallerie Online'
				description={
					<span>
						Kaufen Sie Kunstwerke in unserer Online Kunstgalerie. Ausgewählte KüstlerInnen, welche
						auch schon selbst im
						<strong> KUNSTHAUS MARSTALL</strong> ausgestellt haben, sollen hier mit Kunstliebenden
						verbunden werden. Machen Sie sich ein Bild von den Kunstwerken.
					</span>
				}
				smallScreen={props.smallScreen}
			/>

			{!isMobile && (
				<ArtistsFilter
					value={selectedArtist}
					onChange={(option) => setSelectedArtist(option)}
					options={[{ value: '', label: 'Alle' }, ...artistOptions]}
				/>
			)}

			{isMobile && (
				<div className={styles['filter-dropdown']}>
					<DropdownInput
						options={[{ value: '', label: 'Alle' }, ...artistOptions]}
						onChange={(option) => setSelectedArtist(option)}
						value={selectedArtist}
					/>
				</div>
			)}

			<section className={styles.container}>
				<div className={styles.artworks}>
					{(filteredArts || []).map((item, index) => {
						return <Artwork key={`artwork-${index}`} {...item} />;
					})}
				</div>
			</section>
		</>
	);
};

export default ArtSelling;
