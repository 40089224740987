import React from 'react';
import './Landing.scss';

import Button from '../../components/Button.jsx';

class Landing extends React.Component {
	render() {
		return (
			<div className='landing d-flex flex-column flex-lg-row'>
				<div className='d-flex flex-column teaser'>
					<span className='Eventlocation-Munch'>
						Eventlocation M<span className='text-style-1'>ü</span>
						nchen I <br />
						Starnberg
						<span className='text-style-2'>
							<br />
							Marstall am See
						</span>
					</span>
					<span className='Ihr-stilvoller-Veran'>
						Ihr stilvoller Veranstaltungsort am See für Kunst & Kultur, Hochzeiten, Feiern,
						Modeschauen, Tastings, Märkte, Seminare, Showroom und Filmproduktionen.
					</span>
					<div className='buttonWrapper'>
						<Button
							text='Eventmöglichkeiten'
							onClick={() =>
								document.getElementById('homeEvents').scrollIntoView({ behavior: 'smooth' })
							}
						/>
					</div>
				</div>
				<div className='image'></div>
			</div>
		);
	}
}

export default Landing;
