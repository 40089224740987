import React from 'react';

import './Booking.scss';

import Navbar from '../navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader.jsx';
import InfoPager from '../components/InfoPager.jsx';
import Panorama from './Panorama.jsx';
import Button from '../components/Button.jsx';
import FilterItem from '../components/FilterItem.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStreetView } from '@fortawesome/free-solid-svg-icons';

class Booking extends React.Component {
	constructor(props) {
		super(props);

		this.scrollToCultur = this.scrollToCultur.bind(this);
		this.scrollToEvents = this.scrollToEvents.bind(this);
		this.scrollToInfo = this.scrollToInfo.bind(this);
		this.togglePanorama = this.togglePanorama.bind(this);

		this.cultur = React.createRef();
		this.events = React.createRef();
		this.info = React.createRef();

		this.state = {
			panoramaShown: false
		};
	}

	render() {
		return (
			<div className='booking'>
				<Navbar />
				<PageHeader
					image={'BOOKING_LANDING'}
					title='Besuchen oder Buchen'
					smallScreen={this.props.smallScreen}
				/>
				<div className='booking-nav d-flex flex-row text-center justify-content-between'>
					<FilterItem text='Kultur' onClick={this.scrollToCultur} />
					<FilterItem text='Events' onClick={this.scrollToEvents} />
					<FilterItem text='Info' onClick={this.scrollToInfo} />
				</div>
				<div className='booking-part d-flex flex-column flex-lg-row' ref={this.cultur}>
					<div
						className='booking-part-image'
						style={{
							backgroundImage:
								this.props.smallScreen ? 'url(https://marstall-am-see.de:8443/siteImages/MOBILE/BOOKING_KULTUR)' : 'url(https://marstall-am-see.de:8443/siteImages/DESKTOP/BOOKING_KULTUR)'
						}}
						onClick={this.togglePanorama}
					>
						<FontAwesomeIcon className='panorama-icon' icon={faStreetView} />

						{this.state.panoramaShown && <Panorama closePanorama={this.togglePanorama} />}
					</div>
					<div className='booking-part-text d-flex flex-column align-self-end'>
						<div className='booking-part-title'>Kultur im Kunsthaus am See</div>
						<div className='booking-part-description'>
							Der Saal wurde bereits für eine Vielzahl von Kunstausstellungen, für Konzerte,
							Lesungen, Theater- oder Filmaufführungen und andere Veranstaltungen genutzt. Durch die
							Multifunktionalität der Location ergeben sich für jedes Event neue Möglichkeiten. 340
							m² stehen bereit für Ihre Ideen direkt am Starnberger See – 30 Kilometer von München
							mit guter Autobahnanbindung und Parkmöglichkeiten vor Ort.
						</div>
						<div className='booking-part-btn'>
							<Button text='Termin vereinbaren' url='/contact' />
						</div>
					</div>
				</div>

				<div className='booking-part d-flex flex-column flex-lg-row' ref={this.events}>
					<div
						className='booking-part-image'
						style={{
							backgroundImage:
								this.props.smallScreen ? 'url(https://marstall-am-see.de:8443/siteImages/MOBILE/BOOKING_EVENTS)' : 'url(https://marstall-am-see.de:8443/siteImages/DESKTOP/BOOKING_EVENTS)'
						}}
					></div>
					<div className='booking-part-text d-flex flex-column align-self-end'>
						<div className='booking-part-title'>Events</div>
						<div className='booking-part-description'>
							<p>
								Wer seinen Hochzeitsfeierlichkeiten einen zauberhaften und stilvollen Rahmen
								verleihen möchte, findet in dieser Location die perfekten Voraussetzungen, um dieses
								Ereignis zu einem einmaligen Erlebnis werden zu lassen. Auch private Feste,
								Geburtstags-, Jubiläums-, Trauer- oder Weihnachtsfeiern und Sommerfeste können hier
								stattfinden.
							</p>
							<p>
								Weihnachtsmärkte, Modeschauen oder Tastings - alles ist möglich in diesem
								historischen Gewölbe.{' '}
							</p>
							<p>
								Unternehmen bietet der Saal ideale Bedingungen für Tagungen, Konferenzen, Seminare
								verschiedener Größe und Ausrichtung und Firmenfeiern.
							</p>
							<p>
								Wer seine eigene Produktmesse ausrichten will, kann diesen Veranstaltungsort als
								Showroom individuell ausgestalten.
							</p>
							Auch für Mode- und Werbe-Fotografien oder Filmaufnahmen bietet diese Location beste
							Voraussetzungen.
						</div>
						<div className='booking-part-btn'>
							<Button text='Termin vereinbaren' url='/contact' />
						</div>
					</div>
				</div>

				<div className='booking-part d-flex flex-column flex-lg-row' ref={this.info}>
					<div
						className='booking-part-image'
						style={{
							backgroundImage:
								this.props.smallScreen ? 'url(https://marstall-am-see.de:8443/siteImages/MOBILE/BOOKING_INFO)' : 'url(https://marstall-am-see.de:8443/siteImages/DESKTOP/BOOKING_INFO)'
						}}
					></div>
					<div className='booking-part-text d-flex flex-column align-self-end'>
						<div className='booking-part-title'>Info</div>
						<div className='booking-part-description'>
							Der große Veranstaltungssaal im Erdgeschoss der Location bietet mit einer Fläche von
							340 Quadratmetern und bis zu 3,60 Metern Deckenhöhe ausreichend Raum für Events aller
							Art. Von 40–180 Personen (gedeckte Tische) bis zu 190 Personen (Theaterbestuhlung)
							finden hier Platz. Die gewölbte Decke wird von 16 schlanken gusseisernen Säulen
							getragen. Der Saal ist mit Fußbodenheizung, Unterflurkanälen und ausreichender
							Stromversorgung für alle Einsatzzwecke ausgestattet. Durch die symmetrisch
							angeordneten Fensterreihen steht ausreichend Tageslicht zur Verfügung. Der Garten und
							die Außenanlagen der Location können in die Veranstaltung mit einbezogen werden. Die
							Nähe zur Autobahn sowie der große Parkplatz vor dem Gebäude garantieren einen
							reibungslosen Ablauf. Wir haben im Verlauf der Jahre eine Menge an Tipps und guten
							Kontakten gesammelt, die Ihnen bei der Planung und Realisierung Ihrer Veranstaltung
							nützlich sein können. Sprechen Sie uns an, wir helfen Ihnen gerne oder vereinbaren Sie
							einen Besichtigungstermin für Ihr Event. Kommen Sie bei uns vorbei.
						</div>
						<div className='booking-part-btn'>
							<Button text='Termin vereinbaren' url='/contact' />
						</div>
					</div>
				</div>

				<div
					className='booking-wide-image'
					style={{
						backgroundImage:
							this.props.smallScreen ? 'url(https://marstall-am-see.de:8443/siteImages/MOBILE/BOOKING_INFO_WIDE)' : 'url(https://marstall-am-see.de:8443/siteImages/DESKTOP/BOOKING_INFO_WIDE)'
					}}
				></div>

				<InfoPager smallScreen={this.props.smallScreen} />
			</div>
		);
	}

	scrollToCultur() {
		this.cultur.current.scrollIntoView({
			behavior: 'smooth'
		});
	}

	scrollToEvents() {
		this.events.current.scrollIntoView({
			behavior: 'smooth'
		});
	}

	scrollToInfo() {
		this.info.current.scrollIntoView({
			behavior: 'smooth'
		});
	}

	togglePanorama() {
		this.setState({
			panoramaShown: !this.state.panoramaShown
		});
	}
}

export default Booking;
