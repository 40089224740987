import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import styles from './ImageSlider.module.scss';

const ImageSlider = ({ imageUrls, flatId, onImageClick }) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const goToPrevious = () => {
		setCurrentIndex((oldIndex) => {
			const isFirstSlide = oldIndex === 0;
			const newIndex = isFirstSlide ? imageUrls.length - 1 : oldIndex - 1;
			return newIndex;
		});
	};

	const goToNext = () => {
		setCurrentIndex((oldIndex) => {
			const isLastSlide = oldIndex === imageUrls.length - 1;
			const newIndex = isLastSlide ? 0 : oldIndex + 1;
			return newIndex;
		});
	};

	const handlers = useSwipeable({
		onSwipedLeft: () => goToNext(),
		onSwipedRight: () => goToPrevious()
	});

	return (
		<div {...handlers} className={styles.slider}>
			<img
				className={styles.slide}
				src={`https://marstall-am-see.de:8443/apartments/${flatId}/${imageUrls[currentIndex]}`}
				alt='Slide'
				onClick={() => onImageClick()}
			/>
			<div className={styles.stepnumbers}>
				{currentIndex + 1}/{imageUrls.length}
			</div>
		</div>
	);
};

export default ImageSlider;
