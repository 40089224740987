import React from 'react';

import './HomeItem.scss';

import Button from './Button.jsx';

class HomeItem extends React.Component {
	render() {
		return (
			<div className='home-item' style={{ backgroundImage: 'url(' + this.props.imageName + ')' }}>
				<div className='wrapper d-flex flex-column'>
					<span className='title d-block'>{this.props.title}</span>
					<span className='description'>{this.props.description}</span>
					<div className='buttonWrapper'>
						<Button text={this.props.buttonText} url={this.props.url} />
					</div>
				</div>
			</div>
		);
	}
}

export default HomeItem;
