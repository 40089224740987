import React from 'react';
import './Footer.scss';
import DefaultBtn from '../components/DefaultBtn.js';
import 'animate.css/animate.min.css';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.submitNewsletter = this.submitNewsletter.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.newsletterInput = React.createRef();
		this.markerRef = React.createRef();
		this.footerRef = React.createRef();

		this.state = {
			errorMessage: null,
			prevScrollPos: window.pageYOffset,
			translateOffset: 0,
			successMessage: null,
			newsletterBtnResponsive: true
		};
	}

	render() {
		return (
			<div
				className='background-wrapper'
				style={{ transform: 'translateY(' + this.state.translateOffset + 'px)' }}
				ref={this.footerRef}
			>
				<div style={{ top: '-100vh', position: 'absolute' }} ref={this.markerRef}></div>
				<div className='footer d-flex flex-column side-padding'>
					<div className='header d-flex flex-column justify-content-lg-end'>
						<img src='./footerLogo.svg' className='newsletter-logo' alt='Marstall logo'></img>
					</div>
					<div className='content d-flex flex-column-reverse flex-lg-row-reverse justify-content-between justify-content-lg-center flex-fill'>
						<div className='links-wrapper d-flex flex-column justify-content-end'>
							<div className='links d-flex flex-column justify-content-between text-center text-lg-left'>
								<a className='link' href='/contact'>
									KONTAKT
								</a>
								<a
									className='link'
									href='https://www.instagram.com/marstall_am_see'
									target='_blank'
									rel='noopener noreferrer'
								>
									INSTAGRAM
								</a>
								<a
									className='link'
									href='https://www.facebook.com/marstallamsee'
									target='_blank'
									rel='noopener noreferrer'
								>
									FACEBOOK
								</a>
								<a className='link' href='/impressum'>
									IMPRESSUM
								</a>
								<a className='link' href='/datenschutz'>
									DATENSCHUTZ
								</a>
							</div>
							<div className='location-info d-flex flex-column'>
								{!this.props.smallScreen && (
									<div className='location-info-title'>VERANSTALTUNGSORT</div>
								)}
								<div className='location-info-address'>
									Marstall am See
									<br />
									Mühlgasse 7 <br />
									82335 Berg
								</div>
							</div>
						</div>
						<div className='divider d-flex text-center'>
							<div className='w-50 left'></div>
							<div className='w-50 right'></div>
						</div>
						<div className='newsletter-wrapper'>
							<div className='newsletter text-center text-lg-right d-flex flex-column'>
								<div className='newsletter-title'>
									NEWSLETTER {!this.props.smallScreen && <br />}ANMELDUNG
								</div>
								<div className='info app-font'>
									<span>
										Melden Sie sich für unseren Newsletter an, um immer auf aktuellen Stand zu sein
										und von all unseren Events Wind zu bekommen.
									</span>
									{this.state.errorMessage}
									{this.state.successMessage}
								</div>
								<input
									type='email'
									className='newsletter-input text-center text-lg-right'
									placeholder='Ihre Mail Adresse'
									ref={this.newsletterInput}
								/>
								<div className='btn-wrapper'>
									<DefaultBtn
										className='my-auto'
										btnColor='white'
										text='Jetzt anmelden'
										centerContent={this.props.smallScreen ? 'center' : 'end'}
										onClick={this.state.newsletterBtnResponsive ? this.submitNewsletter : 0}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className='copyright text-center'>&copy; Marstall am See 2022</div>
				</div>
			</div>
		);
	}

	submitNewsletter() {
		var newsletterInput = this.newsletterInput.current;
		var url = 'https://marstall-am-see.de:8443/newsletter';

		this.setState({
			newsletterBtnResponsive: false
		});
		var comp = this;
		fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(newsletterInput.value)
		})
			.then((res) => res.json())
			.then(
				(result) => {
					comp.setState({
						newsletterBtnResponsive: true
					});
					if (result.success) {
						comp.setState({
							errorMessage: null,
							successMessage: (
								<small className='success-message app-font text-success animated bounceInLeft'>
									Vielen dank für die Anmeldung!
								</small>
							)
						});

						setTimeout(function () {
							comp.setState({
								successMessage: null
							});
						}, 3000);

						newsletterInput.value = '';
					} else {
						comp.setState({
							errorMessage: (
								<small className='newsletter-error app-font animated wobble'>{result.error}</small>
							),
							successMessage: null
						});
						setTimeout(function () {
							comp.setState({
								errorMessage: null
							});
						}, 3000);
					}
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					console.log(error);
				}
			);
	}

	componentDidMount() {}

	componentWillUnmount() {}

	handleScroll() {
		var viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		var curScrollPos = window.pageYOffset;
		var scrollDown = this.state.prevScrollPos < curScrollPos;
		var scrollDistance = Math.abs(curScrollPos - this.state.prevScrollPos);

		if (!this.props.smallScreen) {
			if (
				scrollDown &&
				curScrollPos >
					this.footerRef.current.offsetTop + this.state.translateOffset - viewportHeight + 100
			) {
				var distance =
					scrollDistance <=
					this.footerRef.current.offsetTop + this.state.translateOffset - curScrollPos
						? scrollDistance
						: this.footerRef.current.offsetTop + this.state.translateOffset - curScrollPos;
				this.setState({
					translateOffset: this.state.translateOffset - distance
				});
			} else if (!scrollDown && this.state.translateOffset <= 0 && scrollDistance < 100) {
				this.setState({
					translateOffset: this.state.translateOffset + scrollDistance
				});
			}
		}

		this.setState({
			prevScrollPos: curScrollPos
		});
	}
}

export default Footer;
