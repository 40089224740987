import React from 'react';
import createReactClass from 'create-react-class';
import './InfoPager.scss';

import InfoTopic from './InfoTopic.jsx';
import InfoPagerLoadingBar from './InfoPagerLoadingBar.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';

var InfoPager = createReactClass({
	getInitialState: function () {
		return {
			currentTopic: 'Daten',
			mobileResult: false,
			infoClosed: true,
			loadingBarWidth: 0,
			opacity: 1
		};
	},

	render: function () {
		var result = (
			<div className='result' style={{ opacity: this.state.opacity }}>
				<div className='d-flex justify-content-between flex-lg-row-reverse flex-column'>
					<ul>{data[this.state.currentTopic].content}</ul>
					<div
						className='info-image'
						alt={'Marstall' + this.state.currentTopic}
						style={{ backgroundImage: 'url(' + data[this.state.currentTopic].image + ')' }}
					></div>
				</div>
			</div>
		);
		return (
			<div className='info-pager'>
				<div className='header d-flex flex-column flex-lg-row justify-content-between'>
					<div className='small-header d-lg-none'>
						<span className='sub-title' onClick={this.toggleSmall}>
							Infoübersicht
						</span>
						{this.state.infoClosed ? (
							<FontAwesomeIcon
								icon={faChevronDown}
								onClick={
									this.state.infoClosed
										? this.toggleSmall
										: this.state.mobileResult
										? this.closeMobileResult
										: this.toggleSmall
								}
								className='info-pager-button'
							/>
						) : (
							<FontAwesomeIcon
								icon={faTimes}
								onClick={
									this.state.infoClosed
										? this.toggleSmall
										: this.state.mobileResult
										? this.closeMobileResult
										: this.toggleSmall
								}
								className='info-pager-button'
							/>
						)}
					</div>
					<div
						className='topics w-100 d-flex flex-column flex-lg-row justify-content-between'
						style={{
							maxHeight:
								!this.props.smallScreen || !(this.state.mobileResult || this.state.infoClosed)
									? '200rem'
									: 0
						}}
					>
						<InfoTopic
							title='Daten'
							click={this.click}
							currentTopic={this.state.currentTopic}
							callAfterMount={this.setCurrent}
						/>
						<InfoTopic
							title='Downloads'
							click={this.click}
							currentTopic={this.state.currentTopic}
						/>
						<InfoTopic
							title='Ausstattung'
							click={this.click}
							currentTopic={this.state.currentTopic}
						/>
						<InfoTopic title='Catering' click={this.click} currentTopic={this.state.currentTopic} />
						<InfoTopic title='Technik' click={this.click} currentTopic={this.state.currentTopic} />
						<InfoTopic
							title='Dekoration'
							click={this.click}
							currentTopic={this.state.currentTopic}
						/>
						<InfoTopic title='Fotograf' click={this.click} currentTopic={this.state.currentTopic} />
						<InfoTopic title='Trauung' click={this.click} currentTopic={this.state.currentTopic} />
						<InfoTopic title='Hotel' click={this.click} currentTopic={this.state.currentTopic} />
						<InfoTopic title='Parken' click={this.click} currentTopic={this.state.currentTopic} />
						<InfoTopic title='Taxi' click={this.click} currentTopic={this.state.currentTopic} />
						<InfoTopic
							title='Schiffsanlegestelle'
							click={this.click}
							currentTopic={this.state.currentTopic}
						/>
					</div>
					{!this.props.smallScreen && (
						<InfoPagerLoadingBar loadingBarWidth={this.state.loadingBarWidth} />
					)}
					<div
						className='mobile-result d-lg-none '
						style={{ maxHeight: this.state.mobileResult && !this.state.infoClosed ? '200rem' : 0 }}
					>
						<div className='header' onClick={this.closeMobileResult}>
							{this.state.currentTopic}
						</div>
						{result}
					</div>
				</div>
				{!this.props.smallScreen && result}
			</div>
		);
	},

	click: function (topic, item) {
		this.setState({
			opacity: 0
		});
		if (!this.props.smallScreen) {
			this.setState({
				loadingBarWidth: this.resolveLoadingBarWidth(item.current)
			});
		}
		var comp = this;
		setTimeout(
			function () {
				comp.setState({
					currentTopic: topic
				});

				if (comp.props.smallScreen) {
					comp.setState({
						mobileResult: true
					});
				}

				comp.setState({
					opacity: 1
				});
			},
			this.props.smallScreen ? 0 : 500
		);
	},

	resolveLoadingBarWidth: function (selectedItem) {
		return selectedItem.offsetLeft + selectedItem.clientWidth / 2;
	},

	setCurrent: function (item) {
		this.setState({
			loadingBarWidth: this.resolveLoadingBarWidth(item.current)
		});
	},

	closeMobileResult: function () {
		this.setState({ mobileResult: false });
	},

	toggleSmall: function () {
		this.setState({ infoClosed: !this.state.infoClosed });
	}
});

var data = {
	Daten: {
		content: (
			<React.Fragment>
				<li>
					Veranstaltungssaal 340 m²
					<br />
					(abtrennbar in kleinere Bereiche)
				</li>
				<li>Deckenhöhe 3,60 m</li>
				<li>
					Tischbestuhlung für 40–180 Personen
					<br />
					(gedeckte Tische)
				</li>
				<li>Theaterbestuhlung bis zu 190 Personen</li>
				<li>Gartennutzung (bspw. für freie Trauung)</li>
			</React.Fragment>
		),
		image: '/datenInfo.png'
	},
	Downloads: {
		content: (
			<React.Fragment>
				<li>
					<a
						className='d-flex flex-row'
						href='https://marstall-am-see.de/downloads/grundriss.pdf'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img src='./downloadBtn.png' alt='Download button' />
						<span style={{ margin: 'auto', marginLeft: '10px' }}>Grundriss</span>
					</a>
				</li>
				<li>
					<a
						href='https://marstall-am-see.de/downloads/bestuhlung.pdf'
						rel='noopener noreferrer'
						target='_blank'
					>
						<img src='./downloadBtn.png' alt='Download button' />
						<span style={{ margin: 'auto', marginLeft: '10px' }}>Bestuhlungsplan Hochzeit</span>
					</a>
				</li>
				<li>
					<a
						href='http://marstall-am-see.de/downloads/reihenbestuhlung.pdf'
						rel='noopener noreferrer'
						target='_blank'
					>
						<img src='./downloadBtn.png' alt='Download button' />
						<span style={{ margin: 'auto', marginLeft: '10px' }}>Reihenbestuhlungsplan</span>
					</a>
				</li>
			</React.Fragment>
		),
		image: './downloadInfo.png'
	},
	Ausstattung: {
		content: (
			<React.Fragment>
				<li>Gewölbestrahler</li>
				<li>Bilderleisten</li>
				<li>Unterflurkanäle für Kabelverlegung</li>
				<li>Fußbodenheizung</li>
				<li>Starkstromversorgung auch im Außenbereich</li>
				<li>130 Stühle</li>
				<li>6 Stehtische</li>
				<li>16 runde 10er Tische (Durchmesser 190 cm)</li>
				<li>Bar</li>
				<li>Topfpflanzen</li>
				<li>Tischwäsche & Kerzenleuchter zubuchbar</li>
			</React.Fragment>
		),
		image: './equipmentInfo.png'
	},
	Catering: {
		content: (
			<React.Fragment>
				<li>
					Mahavi Catering GmbH
					<br />
					Tel. 089 380 365 80
					<br />
					<a
						href='https://www.mahavi.catering'
						rel='noopener noreferrer'
						target='_blank'
					>
						www.mahavi.catering
					</a>
					<br />
					meinevent@mahavi.catering
				</li>
				<li>
					Zum Fischmeister
					<br />
					Tel. 08177 533
					<br />
					<a href='https://www.zumfischmeister.com' rel='noopener noreferrer' target='_blank'>
						www.zumfischmeister.com
					</a>
				</li>
				<li>
					Who wants it
					<br />
					Wolfgang Weigler
					<br />
					Tel. 0172 7695155
					<br />
					<a href='https://www.whowantsit.de' rel='noopener noreferrer' target='_blank'>
						www.whowantsit.de
					</a>
				</li>
				<li>
					La Cantina
					<br />
					82319 Starnberg
					<br />
					Tel. 08151 666797
				</li>
				<li>
					Kramerfeicht
					<br />
					Steffi und Manuel Liebtrau
					<br />
					Tel. 08151 5706
				</li>
				<li>
					Frohe Feste
					<br />
					Klaus Froschmaier
					<br />
					Tel. 089 847046 0<br />
					<a href='https://www.frohefeste.de' rel='noopener noreferrer' target='_blank'>
						www.frohefeste.de
					</a>
				</li>
				<li>
					Italy
					<br />
					Geretsried
					<br />
					Tel. 08171 31211
				</li>
			</React.Fragment>
		),
		image: './cateringInfo.png'
	},
	Technik: {
		content: (
			<React.Fragment>
				<li>
					Just4fun Veranstaltungstechnik
					<br />
					Verleih von Licht und Ton und noch vielmehr…
					<br />
					Büro: Oberländerstr 2B
					<br />
					Lager: Gotzingerstr. 8 (Halle 4), 81371 München
					<br />
					Tel. 089 74665168
					<br />
					Mail: info@leosjust4fun.de
					<br />
					<a href='https://www.leosjust4fun.de' rel='noopener noreferrer' target='_blank'>
						www.leosjust4fun.de
					</a>
				</li>
				<li>
					VT-VeranstaltungsTechnik GmbH
					<br />
					Emmy-Noether-Str.18, 82216 Maisach
					<br />
					Mail: trusheim@vt-gmbh.com
					<br />
					<a
						href='https://www.vt-veranstaltungstechnik.de'
						rel='noopener noreferrer'
						target='_blank'
					>
						www.vt-veranstaltungstechnik.de
					</a>
				</li>
			</React.Fragment>
		),
		image: './techInfo.png'
	},
	Dekoration: {
		content: (
			<React.Fragment>
				<li>
					Öznur Özen
					<br />
					Tel. 0170 8515345
					<br />
					Mail: hellorosesinapril@gmail.com
					<br />
					Instagram: rosesinapril_
				</li>
			</React.Fragment>
		),
		image: './dekoInfo.png'
	},
	Fotograf: {
		content: (
			<React.Fragment>
				<li>
					Hans-Peter Höck Fotografie
					<br />
					82335 Berg/Aufkirchen
					<br />
					Tel. 08151 51640
					<br />
					Mobil 0177 2993487
					<br />
					<a href='https://www.hoeck-fotografie.de' rel='noopener noreferrer' target='_blank'>
						www.hoeck-fotografie.de
					</a>
				</li>
			</React.Fragment>
		),
		image: './fotoInfo.png'
	},
	Trauung: {
		content: (
			<React.Fragment>
				<li>
					Standesamt
					<br />
					<a href='https://www.gemeinde-berg.de' rel='noopener noreferrer' target='_blank'>
						www.gemeinde-berg.de
					</a>
					<br />
					<a
						href='https://www.starnberg.de/buergerservice-verwaltung/was-erledige-ich-wo/leistungen/hochzeit'
						target='_blank'
						rel='noopener noreferrer'
					>
						www.starnberg.de/buergerservice-verwaltung/was-erledige-ich-wo/leistungen/hochzeit
					</a>
				</li>
				<li>
					Pfarrer
					<br />
					Johannes Habdank
					<br />
					<a href='https://www.berg-evangelisch.de' rel='noopener noreferrer' target='_blank'>
						www.berg-evangelisch.de
					</a>
				</li>
				<li>
					Freie Rednerin
					<br />
					Katharina Heidrich
					<br />
					Mail: kathi@liebesja.com
					<br />
					<a href='https://www.liebesja.com' rel='noopener noreferrer' target='_blank'>
						www.liebesja.com
					</a>
				</li>
			</React.Fragment>
		),
		image: './weddingInfo.png'
	},
	Hotel: {
		content: (
			<React.Fragment>
				<li>
					<a href='https://www.hotelschlossberg.de' rel='noopener noreferrer' target='_blank'>
						www.hotelschlossberg.de
					</a>
				</li>
				<li>
					<a href='https://www.seehotel-leoni.com' rel='noopener noreferrer' target='_blank'>
						www.seehotel-leoni.com
					</a>
				</li>
				<li>
					<a
						href='https://www.vier-jahreszeiten-starnberg.de'
						rel='noopener noreferrer'
						target='_blank'
					>
						www.vier-jahreszeiten-starnberg.de
					</a>
				</li>
				<li>
					<a
						href='https://www.bayerischerhofstarnberg.de'
						rel='noopener noreferrer'
						target='_blank'
					>
						www.bayerischerhofstarnberg.de
					</a>
				</li>
			</React.Fragment>
		),
		image: './hotelInfo.png'
	},
	Parken: {
		content: (
			<React.Fragment>
				<li>
					Ein öffentlicher Parkplatz steht in unmittelbarer Nähe zur Verfügung (Mühlgasse / Ecke
					Waldstraße).
				</li>
				<li>
					Eine kleine Brücke über den Mühlbach führt direkt zum Haupteingang des Marstallgebäudes
					(Eingang Ost).
				</li>
			</React.Fragment>
		),
		image: './parkingInfo.png'
	},
	Taxi: {
		content: (
			<React.Fragment>
				<li>
					Taxi City Starnberg
					<br />
					Tel. 0171 3247656
					<br />
					<a href='https://www.taxicity-starnberg.de' rel='noopener noreferrer' target='_blank'>
						www.taxicity-starnberg.de
					</a>
				</li>
				<li>
					Eibl Taxi Starnberg
					<br />
					Tel. 08151 4111
				</li>
			</React.Fragment>
		),
		image: './taxiInfo.png'
	},
	Schiffsanlegestelle: {
		content: (
			<React.Fragment>
				<li>
					Schiffsanlegestelle Berg
					<br />
					Seestraße 17, 82335 Berg
					<br />
					Tel. 08151 8061
					<br />
					<a
						href='https://www.seenschifffahrt.de/starnberger-see/fahrplan'
						rel='noopener noreferrer'
						target='_blank'
					>
						www.seenschifffahrt.de/starnberger-see/fahrplan
					</a>
				</li>
			</React.Fragment>
		),
		image: './shipInfo.png'
	}
};

export default InfoPager;
