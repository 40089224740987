import React from 'react';

import './Button.scss';

class Button extends React.Component {
	render() {
		return (
			<a
				href={this.props.url}
				className='def-button'
				onClick={this.props.onClick !== undefined ? this.props.onClick : null}
			>
				{this.props.text}
			</a>
		);
	}
}

export default Button;
