import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

import styles from './ImageSlider.module.scss';

const ImageSlider = ({ imageUrls, artId }) => {
	const [currentIndex, setCurrentIndex] = useState(0);

	const goToPrevious = () => {
		setCurrentIndex((oldIndex) => {
			const isFirstSlide = oldIndex === 0;
			const newIndex = isFirstSlide ? imageUrls.length - 1 : oldIndex - 1;
			return newIndex;
		});
	};

	const goToNext = () => {
		setCurrentIndex((oldIndex) => {
			const isLastSlide = oldIndex === imageUrls.length - 1;
			const newIndex = isLastSlide ? 0 : oldIndex + 1;
			return newIndex;
		});
	};

	const goToSlide = (slideIndex) => {
		setCurrentIndex(() => slideIndex);
	};

	const handlers = useSwipeable({
		onSwipedLeft: () => goToNext(),
		onSwipedRight: () => goToPrevious()
	});

	return (
		<div {...handlers} className={styles.slider}>
			<img
				className={styles.slide}
				src={`https://marstall-am-see.de:8443/art/${artId}/${imageUrls[currentIndex]}`}
				alt='Slide'
			/>

			{imageUrls && imageUrls.length > 1 && (
				<>
					<button className={styles['arrow-left']} onClick={() => goToPrevious()}>
						<span>&#10094;</span>
					</button>
					<button className={styles['arrow-right']} onClick={() => goToNext()}>
						<span>&#10095;</span>
					</button>
				</>
			)}

			<div className={styles.dots}>
				{imageUrls.map((_, slideIndex) => (
					<button
						key={`dot-${slideIndex}`}
						className={slideIndex === currentIndex ? styles['dot-selected'] : styles.dot}
						onClick={() => goToSlide(slideIndex)}
					></button>
				))}
			</div>
		</div>
	);
};

export default ImageSlider;
