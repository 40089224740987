import React from 'react';
import './InfoPagerLoadingBar.scss';

class InfoPagerLoadingBar extends React.Component {
	render() {
		return (
			<div className='loading-bar d-flex flex-row'>
				<div className='left' style={{ width: this.props.loadingBarWidth + 'px' }}></div>
				<div className='right flex-fill'></div>
			</div>
		);
	}
}

export default InfoPagerLoadingBar;
