import React from 'react';

import './Events.scss';
import HomeItem from '../../components/HomeItem.jsx';

class Events extends React.Component {
	render() {
		return (
			<div id='homeEvents' className='events d-flex flex-column'>
				<div className='section-title'>
					{!this.props.smallScreen ? (
						<span className='Events-und-Kulturver'>
							Events und Kulturveranstaltungen
							<span className='text-style-1'>
								<br />
								im Marstall am See
							</span>
						</span>
					) : (
						<span className='Events-und-Kulturver'>
							Events und Kultur-
							<br />
							veranstaltungen
							<span className='text-style-1'>
								<br />
								im Marstall am See
							</span>
						</span>
					)}
				</div>
				<div className='items d-flex flex-row'>
					<HomeItem
						title='Hochzeiten und Feiern'
						description='Feiern Sie mit uns Ihre freie Trauung oder verbinden Sie Ihr Event mit einer Ausfahrt in unserem FIAT 500 oder einem anderen Oldtimer.'
						buttonText='Termin vereinbaren'
						imageName='WEDDING_DESKTOP.png'
						url='/contact?reason=Hochzeit und Feiern'
					/>
					<HomeItem
						title='Modeschauen, Tastings, Weihnachts-märkte'
						description='Der ideale Ort für Ihre Produktpräsentation.'
						buttonText='Termin vereinbaren'
						imageName='MODE_TASTINGS_DESKTOP.png'
						url='/contact?reason=Modeschauen, Tastings, Weihnachts-märkte'
					/>
					<HomeItem
						title='Seminare, Tagungen'
						description='Wir bieten Ihnen Raum für Ihren Workshop.'
						buttonText='Termin vereinbaren'
						imageName='SEMINARE_DESKTOP.png'
						url='/contact?reason=Seminare, Tagungenn'
					/>
					<HomeItem
						title='Foto und Filmlocation'
						description='  Ein idealer Drehort für kreative Auftritte direkt am Starnberger See. Wir bieten Ihnen viel Platz für Ihre Filmproduktion.'
						buttonText='Termin vereinbaren'
						imageName='PHOTO_FILM_DESKTOP.png'
						url='/contact?reason=Foto- und Filmlocation'
					/>
					<HomeItem
						title='Kunst und Kultur'
						description='Vereinbaren Sie einen Termin für Ihre Kulturveranstaltung.'
						buttonText='Termin vereinbaren'
						imageName='KUNST_KULTUR_DESKTOP.png'
						url='/contact?reason=Kunst und Kultur'
					/>
					<HomeItem
						title='Showroom'
						description='Kommen Sie vorbei und sehen Sie selbst für Ihren Showroom.'
						buttonText='Termin vereinbaren'
						imageName='SHOWROOM_DESKTOP.png'
						url='/contact?reason=Showroom'
					/>
				</div>
			</div>
		);
	}
}

export default Events;
