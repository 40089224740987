import React, { useEffect, useState, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import Navbar from '../navbar/Navbar';
import PageHeader from '../components/PageHeader';
import DropdownInput from '../components/dropdown-input/DropdownInput';
import CitiesFilter from '../components/filter-buttons/FilterButtons';
import { Flat } from './components';

import styles from './LiveInArt.module.scss';

const LiveInArt = (props) => {
	const [apartmentsData, setApartmentsData] = useState([]);
	const [selectedCity, setSelectedCity] = useState({ value: '', label: 'Alle' });
	const [cityOptions, setCityOptions] = useState([]);

	const isMobile = useMediaQuery({ query: `(max-width: 768px)` });

	const getApartmentsData = async () => {
		try {
			const response = await fetch('https://marstall-am-see.de:8443/apartments');
			const data = await response.json();

			if (response.status === 200 && data) {
				let tempCityOptions = new Map();

				for (const apartment of data) {
					if (apartment.city && apartment.city !== '') {
						tempCityOptions.set(apartment.city, { value: apartment.city, label: apartment.city });
					}
				}

				setApartmentsData(data || []);
				setCityOptions([...tempCityOptions.values()]);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getApartmentsData();
	}, []);

	const filteredApartments = useMemo(() => {
		if (selectedCity.value === '') return apartmentsData;

		return (apartmentsData || []).filter((apartment) => selectedCity.value === apartment.city);
	}, [selectedCity, apartmentsData]);

	return (
		<>
			<Navbar />
			<PageHeader
				image='APARTMENT_LANDING'
				title='Kunstraum – Wohnen in Kunst'
				description={
					<span>
						In unserem KUNSTRÄUMEN finden Sie Freiraum und Rückzugsmöglichkeit für Ihren Aufenthalt
						am Starnberger See, in München und auch in Leipzig. Gehen Sie auf Entdeckungstour für
						Ihre Übernachtung.
					</span>
				}
				smallScreen={props.smallScreen}
			/>

			{!isMobile && (
				<CitiesFilter
					value={selectedCity}
					onChange={(option) => setSelectedCity(option)}
					options={[{ value: '', label: 'Alle' }, ...cityOptions]}
				/>
			)}

			{isMobile && (
				<div className={styles['filter-dropdown']}>
					<DropdownInput
						options={[
							{ value: '', label: 'Alle' },
							{ value: 'Easy', label: 'Easy' },
							...cityOptions
						]}
						onChange={(option) => setSelectedCity(option)}
						value={selectedCity}
					/>
				</div>
			)}

			<section className={styles.flats}>
				{(filteredApartments || []).map((item, index) => {
					return <Flat key={`flat-${index}`} {...item} />;
				})}
			</section>
		</>
	);
};

export default LiveInArt;
