import React from 'react';

import './Art.scss';
import HomeItem from '../../components/HomeItem.jsx';

class Art extends React.Component {
	render() {
		return (
			<div className='art d-flex flex-column'>
				<div className='title'>
					<div className='Kunst'>Kunst und Wohnen</div>
				</div>
				<div className='items d-flex flex-row'>
					<HomeItem
						title='Kunstverkauf'
						description='Kaufen Sie Kunstwerke in unserer Online Kunstgalerie. Machen Sie sich ein Bild von den Kunstwerken.'
						buttonText='Mehr Infos'
						imageName='KUNSTVERKAUF_DESKTOP.png'
						url='/art-selling'
					/>
					<HomeItem
						title='Kunstraum'
						description='Finden Sie Freiraum und Rückzugs-möglichkeit für Ihren Aufenthalt am Starnberger See und anderswo.'
						buttonText='Mehr Infos'
						imageName='KUNSTRAUM_DESKTOP.png'
						url='/live-in-art'
					/>
				</div>
			</div>
		);
	}
}

export default Art;
