import React from 'react';
import './Item.scss';
import { useHistory } from 'react-router-dom';

function Item(props) {
	var history = useHistory();

	return (
		<div
			className='item'
			onClick={() => {
				history.push('/calender?date=' + props.currentDate);
			}}
		>
			<div className='wrapper'>
				<div className='date'>{props.date}</div>
				<div className='day'>{props.day}</div>
			</div>
		</div>
	);
}

export default Item;
