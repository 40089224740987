import styled from 'styled-components';
import Dropdown from 'react-dropdown-select';

export const StyledDropdown = styled(Dropdown)`
	background-color: #1d0a96;
	color: #fff;
	border-radius: 8px !important;
	padding: 10px 30px !important;
	font-family: Poppins-Bold;
	font-size: 16px;

	.react-dropdown-select-dropdown-handle {
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		svg {
			width: 25px;
			height: 25px;
		}
	}

	.react-dropdown-select-content {
		display: flex;
		justify-content: center;
	}

	.react-dropdown-select-content {
		display: flex;
		justify-content: center;
	}

	.react-dropdown-select-input {
		&::placeholder {
			color: #fff;
		}
	}

	.react-dropdown-select-dropdown {
		color: #000;
		font-family: Poppins-Regular;
		border-radius: 8px;
	}

	.react-dropdown-select-item {
		border: none;
	}

	.react-dropdown-select-item-selected[aria-selected='true'] {
		background-color: rgba(29, 10, 150, 0.4);
		color: #000;
		border: none;
		font-weight: bold;
	}
`;
