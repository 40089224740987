import React from 'react';

import './CalenderResultSimple.scss';

import Button from './Button.jsx';

class CalenderResultSimple extends React.Component {
	render() {
		return (
			<div className='calender-result-simple'>
				<div className='result-simple-date'>
					{this.props.date.toLocaleDateString('de-DE', {
						weekday: 'long',
						year: 'numeric',
						month: 'long',
						day: 'numeric'
					})}
				</div>
				<div className='result-simple-title'>{this.props.title}</div>
				<div className='result-simple-description'>{this.props.description}</div>
				{this.props.buttonVisible && <Button text='Termin vereinbaren' url='/contact' />}
			</div>
		);
	}
}

export default CalenderResultSimple;
