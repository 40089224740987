import React from 'react';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import smoothscroll from 'smoothscroll-polyfill';

import Home from './app/home/Home.jsx';
import About from './app/about/About.jsx';
import Impressionen from './app/impressionen/Impressionen.jsx';
import Booking from './app/booking/Booking.jsx';
import Calender from './app/calender/Calender.jsx';
import Contact from './app/contact/Contact.jsx';
import Impressum from './app/impressum/Impressum.jsx';
import Datenschutz from './app/datenschutz/Datenschutz.jsx';
import NotFound from './app/NotFound.jsx';
import ArtSelling from './app/art-selling/ArtSelling';
import LiveInArt from './app/live-in-art/LiveInArt';

import Footer from './app/footer/Footer.jsx';
import history from './history.js';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.bottomMarker = React.createRef();
		this.setCurrentDate = this.setCurrentDate.bind(this);
		this.setBookingScrollTo = this.setBookingScrollTo.bind(this);
		this.setScrollToContactForm = this.setScrollToContactForm.bind(this);
		this.setFooterShown = this.setFooterShown.bind(this);

		this.state = {
			smallScreen: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 992,
			currentDate: null,
			bookingScrollTo: null,
			scrollToContactForm: false,
			bottomFromTop: 0,
			dateMap: {},
			footerShown: true
		};
	}

	render() {
		return (
			<div className='app-container'>
				<Router history={history}>
					<Switch>
						<Route
							exact
							path='/'
							render={(props) => (
								<Home
									smallScreen={this.state.smallScreen}
									setCurrentDate={this.setCurrentDate}
									setBookingScrollTo={this.setBookingScrollTo}
								/>
							)}
						/>
						<Route
							path='/about'
							render={(props) => <About smallScreen={this.state.smallScreen} />}
						/>
						{
							<Route
								path='/impressionen'
								render={(props) => (
									<Impressionen
										smallScreen={this.state.smallScreen}
										bottomFromTop={this.state.bottomFromTop}
									/>
								)}
							/>
						}
						<Route
							path='/booking'
							render={(props) => <Booking smallScreen={this.state.smallScreen} />}
						/>
						<Route
							path='/calender'
							render={(props) => (
								<Calender
									smallScreen={this.state.smallScreen}
									currentDate={this.state.currentDate}
									setCurrentDate={this.setCurrentDate}
									setScrollToContactForm={this.setScrollToContactForm}
									bottomFromTop={this.state.bottomFromTop}
									dateMap={this.state.dateMap}
								/>
							)}
						/>
						<Route
							path='/contact'
							render={(props) => (
								<Contact
									smallScreen={this.state.smallScreen}
									scrollToContactForm={this.state.scrollToContactForm}
									setScrollToContactForm={this.setScrollToContactForm}
								/>
							)}
						/>
						<Route
							path='/impressum'
							render={(props) => <Impressum smallScreen={this.state.smallScreen} />}
						/>
						<Route
							path='/datenschutz'
							render={(props) => <Datenschutz smallScreen={this.state.smallScreen} />}
						/>
						<Route
							path='/art-selling'
							render={() => <ArtSelling smallScreen={this.state.smallScreen} />}
						/>
						<Route
							path='/live-in-art'
							render={() => <LiveInArt smallScreen={this.state.smallScreen} />}
						/>
						<Route render={(props) => <NotFound setFooterShown={this.setFooterShown} />} />
					</Switch>
				</Router>
				{this.state.footerShown && <Footer smallScreen={this.state.smallScreen} />}
				<div className='bottom-marker' ref={this.bottomMarker}></div>
			</div>
		);
	}

	setFooterShown(shown) {
		this.setState({
			footerShown: shown
		});
	}

	componentDidMount() {
		this.setState({
			bottomFromTop: this.bottomMarker.current.getBoundingClientRect().top
		});

		var comp = this;
		window.addEventListener('resize', function () {
			comp.setState({
				smallScreen: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 992
			});
		});
		smoothscroll.polyfill();

		this.fetchDateMap();
	}

	fetchDateMap() {
		var url = 'https://marstall-am-see.de:8443/termin/dateMap';

		fetch(url)
			.then((res) => res.json())
			.then(
				(result) => {
					this.setState({
						dateMap: result
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					console.log(error);
				}
			);
	}

	setCurrentDate(date) {
		this.setState({
			currentDate: date
		});
	}

	setBookingScrollTo(option) {
		this.setState({
			bookingScrollTo: option
		});
	}

	setScrollToContactForm(decision) {
		this.setState({
			scrollToContactForm: decision
		});
	}
}

export default App;
