import React from 'react';

import Button from '../../../components/Button.jsx';
import Chip from '../../../components/chip/Chip';
import ImageSlider from '../image-slider/ImageSlider.jsx';

import styles from './Artwork.module.scss';

const Artwork = (props) => {
	const { id, pictures, artist, price, title, description } = props;

	return (
		<div className={styles.card}>
			<div className={styles['image-wrapper']}>
				<ImageSlider artId={id} imageUrls={pictures || []} />
			</div>
			<div className={styles.details}>
				<div className={styles.price}>
					<Chip value={`${price || 0} €`} />
				</div>
				<h3 className={styles.title}>{title || ''}</h3>
				<p className={styles.description}>
					{(artist && `von ${artist}, `) || ''}
					{description || ''}
				</p>
				<Button text='Termin vereinbaren' url={`/contact?reason=${title}`} />
			</div>
		</div>
	);
};

export default Artwork;
