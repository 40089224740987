import React from 'react';

import './Calender.scss';

import Navbar from '../navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader.jsx';
import CalenderResultSimple from '../components/CalenderResultSimple.jsx';
import CalenderResultEvent from '../components/CalenderResultEvent.jsx';
import Calendar from 'react-calendar';

class Calender extends React.Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);

		this.state = {
			initialDate: new Date(),
			currentDate: new Date(),
			resultView: []
		};
	}

	render() {
		return (
			<div className='calender d-flex flex-column'>
				<Navbar />
				<PageHeader
					image={'CALENDER_LANDING'}
					title='Kalender'
					smallScreen={this.props.smallScreen}
				/>
				<div className='calender-text'>
					<p>
						An vielen Terminen im Jahr wird der Saal für Kunstausstellungen und andere kulturelle
						Veranstaltungen genutzt. Ein aktuelles Programm wird über einen Newsletter zur Verfügung
						gestellt.
					</p>
					Wir freuen uns über ein Feedback zu unseren Veranstaltungen und darüber, wenn Sie unsere
					Termine weitersagen.
				</div>
				<div className='calender-widget-container'>
					<Calendar
						onChange={this.onChange}
						calendarType='ISO 8601'
						locale='de'
						value={this.state.currentDate}
						minDetail='month'
						maxDetail='month'
						minDate={new Date()}
						showDoubleView={!this.props.smallScreen}
						showNeighboringMonth={false}
						showFixedNumberOfWeeks={false}
						selectRange={false}
						tileContent={({ date, view }) => {
							var className = '';
							var dateMap = this.props.dateMap;
							var formatedDate =
								date.getFullYear() +
								'-' +
								('0' + (date.getMonth() + 1)).slice(-2) +
								'-' +
								('0' + date.getDate()).slice(-2);

							if (date.getTime() === this.state.currentDate.getTime()) {
								className = className + ' current-day';
							}

							if (dateMap[formatedDate] !== undefined) {
								console.log(dateMap[formatedDate]);
								console.log(formatedDate);
							}

							return (
								<div className='d-flex flex-column'>
									<div className={className}>{date.toString()[8] + date.toString()[9]}</div>
									{dateMap[formatedDate] !== undefined && <div className='day-marker'></div>}
								</div>
							);
						}}
					/>
				</div>
				<div className='calender-current-date'>
					{this.state.currentDate.toLocaleDateString('de-DE', {
						year: 'numeric',
						month: 'long',
						day: 'numeric'
					})}
				</div>
				<div
					className='calender-result'
					style={{ opacity: this.state.resultOpacity, transition: 'all .5s ease' }}
				>
					{this.state.resultView.length > 0 ? (
						this.state.resultView
					) : (
						<CalenderResultSimple
							date={this.state.currentDate}
							title='Frei'
							description='Melden Sie sich einfach für den Termin, der Ihnen passt. Wir freuen uns von Ihnen zu hören!'
							buttonVisible={true}
						/>
					)}
				</div>
			</div>
		);
	}

	componentDidMount() {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const date = params.get('date');

		this.onChange(date !== null ? new Date(date) : new Date());
	}

	onChange(date) {
		this.setState({
			currentDate: date,
			resultOpacity: 0.4
		});
		var url =
			'https://marstall-am-see.de:8443/termin/' +
			date.getFullYear() +
			'-' +
			('0' + (date.getMonth() + 1)).slice(-2) +
			'-' +
			('0' + date.getDate()).slice(-2);

		var comp = this;

		this.setState({
			resultView: []
		});
		fetch(url)
			.then((res) => res.json())
			.then(
				(result) => {
					var resultViewTmp = [];

					result.forEach(function (termin, index) {
						if (termin.type === 'EVENT') {
							resultViewTmp.push(<CalenderResultEvent event={termin} key={comp.state.currentDate} />);
						}
						if (termin.type === 'BOOKED') {
							resultViewTmp.push(
								<CalenderResultSimple
									date={comp.state.currentDate}
									title='Gebucht'
									description='Leider ist dieser Termin bereits vergeben.'
									buttonVisible={false}
									key={comp.state.currentDate}
								/>
							);
						}
						if (termin.type === 'ASKED') {
							resultViewTmp.push(
								<CalenderResultSimple
									date={comp.state.currentDate}
									title='Angefragt'
									description='Dieser Termin wurde bereits angefragt, aber ist noch nicht endgültig vergeben.'
									buttonVisible={false}
								/>
							);
						}
					});

					comp.setState({
						resultView: resultViewTmp,
						resultOpacity: 1
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					console.log(error);
				}
			);
	}
}

export default Calender;
