import React, { useState } from 'react';

import { FlatDetails, FlatGallery, ImageSlider } from '..';
import getFirstThree from '../../utils/get-first-three';

import styles from './Flat.module.scss';

const Flat = (props) => {
	const { id, pictures } = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const firstThreeImages = pictures ? getFirstThree(pictures) : [];

	const toggleModalVisibility = () => setIsModalOpen((oldValue) => !oldValue);

	return (
		<div className={styles.container}>
			<ImageSlider
				onImageClick={() => toggleModalVisibility()}
				flatId={id}
				imageUrls={firstThreeImages}
			/>
			<FlatGallery
				onImageClick={() => toggleModalVisibility()}
				flatId={id}
				imageUrls={firstThreeImages}
			/>
			<FlatDetails
				isModalOpen={isModalOpen}
				onDetailsClick={() => toggleModalVisibility()}
				{...props}
			/>
		</div>
	);
};

export default Flat;
