import React from 'react';

import './Menu.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { RemoveScroll } from 'react-remove-scroll';

class Menu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			smallScreen: Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 992
		};
	}

	render() {
		return (
			<RemoveScroll>
				<div className='menu text-center d-flex flex-column'>
					<div className='header-wrapper d-flex flex-row justify-content-between'>
						{/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
						<a href='/' className='logo'></a>
						<div className='d-flex flex-row justify-content-end'>
							{this.state.smallScreen && (
								<a
									href='https://www.facebook.com/marstallamsee'
									rel='noopener noreferrer'
									target='_blank'
								>
									<FontAwesomeIcon className='menu-facebook' icon={faFacebook} />
								</a>
							)}

							{this.state.smallScreen && (
								<a
									href='https://www.instagram.com/marstall_am_see'
									rel='noopener noreferrer'
									target='_blank'
								>
									<FontAwesomeIcon className='menu-instagram' icon={faInstagram} />
								</a>
							)}
							<div className='close-button-wrapper'>
								<FontAwesomeIcon
									className='close-button'
									icon={faTimes}
									onClick={this.props.closeMenu}
								/>
							</div>
						</div>
					</div>
					<div className='items-container flex-fill d-flex flex-column justify-content-between align-items-start'>
						<a href='/calender' className='menu-item'>
							Kalender
						</a>
						<a href='/about' className='menu-item'>
							Über uns
						</a>
						<a href='/booking' className='menu-item'>
							Besuchen oder Buchen
						</a>
						<a href='/impressionen' className='menu-item'>
							Impressionen
						</a>
						<a href='/art-selling' className='menu-item'>
							Kunstverkauf
						</a>
						<a href='/live-in-art' className='menu-item'>
							Kunstraum - Wohnen in Kunst
						</a>
						<a href='/contact' className='menu-item'>
							Kontakt
						</a>
					</div>
				</div>
			</RemoveScroll>
		);
	}
}

export default Menu;
