import React from 'react';

import './AlbumItem.scss';

class AlbumItem extends React.Component {
	render() {
		return (
			<div
				className='album-item d-flex justify-content-end pt-4 pr-4'
				style={{
					backgroundImage:
						'url(https://marstall-am-see.de:8443/gallery/' +
						this.props.albumId +
						'/' +
						this.props.pictureName +
						')'
				}}
			>
				<div className='album-item-position text-center'>
					{this.props.position + 1 + '/' + this.props.albumSize}
				</div>
			</div>
		);
	}
}

export default AlbumItem;
