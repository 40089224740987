import React from 'react';
import './Panorama.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

class Panorama extends React.Component {
	render() {
		return (
			<div className='panorama-wrapper'>
				<iframe
					src='https://gallerylau.de/Berg/Pano1.html'
					title='Panorama'
					className='panorama'
				></iframe>
				<FontAwesomeIcon
					className='exit-icon'
					icon={faTimes}
					onClick={() => this.props.closePanorama()}
				/>
			</div>
		);
	}
}

export default Panorama;
