import React from 'react';
import './NotFound.scss';

class NotFound extends React.Component{

	render(){
		return(
			<div class="not-found d-flex justify-content-center align-items-center flex-column">
				<h1>404 Not Found</h1>
				<div class="">Die gesuchte Seite wurde leider nicht gefunden.</div>
				<div class="">Klicken Sie <a href="/">hier</a> um auf Startseite zu landen.</div>
			</div>
		);
	}

	componentDidMount(){
		this.props.setFooterShown(false);
	}

	componentWillUnmount(){
		this.props.setFooterShown(true);
	}
}

export default NotFound;