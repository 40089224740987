import React from 'react';

import './Partner.scss';

class Partner extends React.Component {
	render() {
		return (
			<div className='partner d-flex flex-column'>
				<div className='title align-items-center'>Unsere Partner</div>
				<div className='partnerWrapper d-flex flex-column flex-lg-row justify-content-lg-between'>
					<img src='PAD_LOGO.png' alt='Partner logo' className='partnerImage padLogo'></img>
					<img src='MAHAVI_LOGO.png' alt='Partner logo' className='partnerImage'></img>
					<img src='PADIO_LOGO.png' alt='Partner logo' className='partnerImage padioLogo'></img>
				</div>
			</div>
		);
	}
}

export default Partner;
