import React from 'react';

import './PageHeader.scss';

class PageHeader extends React.Component {
	render() {
		const { image, smallScreen, title, description } = this.props;
		const host = 'https://marstall-am-see.de:8443';
		const path = `/siteImages/${smallScreen ? 'MOBILE' : 'DESKTOP'}/`;

		return (
			<div>
				<div
					className='pageHeader'
					style={{ backgroundImage: `url(${host}${path}${image})` }}
				></div>
				<h1 className='pageHeader-title'>{title}</h1>
				{description && <p className='pageHeader__description'>{description}</p>}
			</div>
		);
	}
}

export default PageHeader;
