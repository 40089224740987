import React from 'react';

import './CalenderResultEvent.scss';

class CalenderResultEvent extends React.Component {
	render() {
		return (
			<div className='calender-result-event d-flex flex-column flex-lg-row justify-content-between'>
				<div className='result-event-info d-flex flex-column'>
					<div className='event-info-type'>{this.props.event.terminType}</div>
					<div className='event-info-author'>{this.props.event.author}</div>
					<div className='event-info-description'>{this.props.event.description}</div>
					<div className='event-entry-info d-flex flex-column'>
						Einlass {this.props.event.startTime} Uhr
						<br />
						{this.props.event.price > 0
							? 'Eintritt ' + this.props.event.price + ' €'
							: 'Eintritt frei'}
						<br />
						Marstall, Mühlgasse 7, 82335 Berg
					</div>
				</div>

				<div
					className='result-event-image'
					style={{
						backgroundImage:
							'url(https://marstall-am-see.de:8443/termin/image/' +
							this.props.event.pictureName +
							')'
					}}
				></div>
			</div>
		);
	}
}

export default CalenderResultEvent;
