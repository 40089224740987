import React from 'react';

import styles from './FlatGallery.module.scss';

const FlatGallery = ({ imageUrls, flatId, onImageClick }) => {
	return (
		<div className={styles.gallery}>
			{imageUrls.map((url, index) => (
				<img
					key={`gallery-image-${index}`}
					src={`https://marstall-am-see.de:8443/apartments/${flatId}/${url}`}
					alt='Flat gallery'
					onClick={() => onImageClick()}
				/>
			))}
		</div>
	);
};

export default FlatGallery;
