import React from 'react';

import './Impressionen.scss';
import Navbar from '../navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader.jsx';
import Gallery from './Gallery.jsx';

class Impressionen extends React.Component {
	render() {
		return (
			<div className='impressionen'>
				<Navbar />
				<PageHeader
					image={'IMPRESSIONEN_LANDING'}
					title='Impressionen'
					smallScreen={this.props.smallScreen}
				/>
				<Gallery smallScreen={this.props.smallScreen} />
			</div>
		);
	}
}

export default Impressionen;
