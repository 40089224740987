const getFirstThree = (items) => {
	let firstThree = [];

	for (let i = 0; i < items.length; i++) {
		if (i > 2) break;
		firstThree.push(items[i]);
	}

	return firstThree;
};

export default getFirstThree;
