import React from 'react';
import './Contact.scss';
import Navbar from '../navbar/Navbar.jsx';
import Button from '../components/Button.jsx';
import Select from 'react-dropdown-select';
import ContactCheckbox from '../components/ContactCheckbox.jsx';

class Contact extends React.Component {
	constructor(props) {
		super(props);
		this.submitContactForm = this.submitContactForm.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleAnredeChange = this.handleAnredeChange.bind(this);
		this.toggleMenuHandle = this.toggleMenuHandle.bind(this);
		this.openMenu = this.openMenu.bind(this);
		this.onCheckboxChange = this.onCheckboxChange.bind(this);
		this.scrollToForm = this.scrollToForm.bind(this);

		this.vornameInput = React.createRef();
		this.nachnameInput = React.createRef();
		this.emailInput = React.createRef();
		this.landInput = React.createRef();
		this.handyInput = React.createRef();
		this.messageInput = React.createRef();

		this.contactFormMarker = React.createRef();

		this.state = {
			anrede: '',
			vorname: '',
			nachname: '',
			grund: '',
			email: '',
			handy: '',
			land: '',
			message: '',
			anredeError: '',
			vornameError: '',
			nachnameError: '',
			grundError: '',
			emailError: '',
			landError: '',
			messageError: '',
			confirmationError: '',
			checkboxChecked: false,
			checkboxError: false,
			clearTrigger: true,
			submitBtnResponsive: true,
			contactFormOpacity: 1
		};
	}

	render() {
		const search = window.location.search;
		const params = new URLSearchParams(search);

		return (
			<div className='contact'>
				<Navbar />
				<div className='contact-landing d-flex flex-column justify-content-between'>
					<div className='d-flex flex-column justify-content-center flex-fill contact-title side-padding'>
						<span>
							FÜR FRAGEN, ANFRAGEN, RESERVIERUNGEN & LIEBESBRIEFE: MELDEN SIE SICH JEDERZEIT BEI
							UNS! WIR FREUEN UNS AUF SIE!
						</span>
					</div>
					<div className='arrow-wrapper text-center'>
						<img src='./arrow.svg' className='arrow' alt='Arrow'></img>
					</div>
				</div>
				<div
					className='contact-form side-padding'
					ref={this.contactFormMarker}
					style={{ opacity: this.state.contactFormOpacity }}
				>
					<div className='title text-center d-lg-none'>KONTAKT</div>
					<div>
						{this.state.clearTrigger && (
							<Select
								options={[
									{ value: 'Frau', label: 'Frau' },
									{ value: 'Herr', label: 'Herr' }
								]}
								onChange={(values) => this.handleAnredeChange(values)}
								className='anrede-input'
								placeholder='Anrede'
								name='Anrede'
							/>
						)}
					</div>
					<div className='row justify-content-between'>
						<div className='col-12 col-lg-6 p-0'>
							<input
								name='vorname'
								type='text'
								placeholder='Vorname *'
								onChange={this.handleChange}
								ref={this.vornameInput}
							/>
							{this.state.vornameError}
						</div>
						<div className='col-12 col-lg-6 text-right p-0'>
							<input
								name='nachname'
								type='text'
								placeholder='Nachname *'
								onChange={this.handleChange}
								ref={this.nachnameInput}
							/>
							{this.state.nachnameError}
						</div>
						<div className='col-12 col-lg-6 text-right p-0'>
							<input
								value={this.state.grund !== null ? this.state.grund : null}
								name='grund'
								className='grund-input'
								type='text'
								placeholder='Grund der Kontktaufnahme *'
								onChange={this.handleChange}
							/>
							{this.state.grundError}
						</div>
						<div className='col-12 col-lg-6 text-right p-0'>
							<input
								name='email'
								type='email'
								placeholder='E-Mail Adresse *'
								onChange={this.handleChange}
								ref={this.emailInput}
							/>
							{this.state.emailError}
						</div>
						<div className='col-12 col-lg-6 p-0'>
							<input
								name='handy'
								type='text'
								placeholder='Telefonnummer/Handy'
								onChange={this.handleChange}
								ref={this.handyInput}
							/>
						</div>
						<div className='col-12 col-lg-6 text-right p-0'>
							<input
								name='land'
								type='text'
								placeholder='Land *'
								onChange={this.handleChange}
								ref={this.landInput}
							/>
							{this.state.landError}
						</div>
					</div>
					<div style={{ position: 'relative' }}>
						<textarea
							placeholder='Nachricht *'
							name='message'
							rows='5'
							onChange={this.handleChange}
							ref={this.messageInput}
						/>
						{this.state.messageError}
					</div>
					<div className='d-flex flex-row'>
						{this.state.clearTrigger && (
							<ContactCheckbox
								onChange={this.onCheckboxChange}
								errorShown={this.state.checkboxError}
							/>
						)}
						<span className='age-info'>
							Ich bin über 16 Jahre alt und bin damit einverstanden, dass meine persönlichen Daten
							in diesem Formular von der Marstall am See zur Erfüllung meiner Anfrage verarbeitet
							und gespeichert werden. Marstall am See hat Maßnahmen ergriffen, um meine persönlichen
							Daten angemessen zu schützen. Um mehr darüber zu erfahren, wie wir die von Ihnen
							bereitgestellten Daten verwalten, sehen Sie sich unsere
							<a href='/datenschutz' style={{ textDecoration: 'underline', color: 'black' }}>
								{' '}
								Datenschutzrichtlinie
							</a>{' '}
							an. *
						</span>
					</div>
					<div className='btn-wrapper'>
						{this.state.successMessage}
						<Button
							text='Absenden'
							onClick={this.state.submitBtnResponsive ? this.submitContactForm : null}
						/>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		window.scrollTo(0, 0);

		const search = window.location.search;
		const params = new URLSearchParams(search);
		const reason = params.get('reason');

		if (reason !== null) {
			this.setState({
				grund: reason
			});
			this.scrollToForm();
		}
	}

	scrollToForm() {
		this.contactFormMarker.current.scrollIntoView({ behavior: 'smooth' });
	}

	submitContactForm() {
		var url = 'https://marstall-am-see.de:8443/contact';

		if (!this.state.checkboxChecked) {
			this.setState({
				checkboxError: true
			});
			return null;
		}

		var comp = this;
		this.setState({
			submitBtnResponsive: false,
			contactFormOpacity: 0.3
		});
		fetch(url, {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(this.state)
		})
			.then((res) => res.json())
			.then(
				(result) => {
					if (result.success) {
						comp.setState({
							clearTrigger: false
						});
						setTimeout(function () {
							comp.setState({
								clearTrigger: true
							});
						}, 0);
						comp.resetForm();
						comp.setState({
							successMessage: (
								<div className='success-message animated zoomInUp	 text-success'>
									Wir haben Ihre Nachricht erhalten
									<br className='d-lg-none' /> und werden uns <br />
									so schnell wie möglich bei Ihnen melden.
								</div>
							)
						});
						setTimeout(function () {
							comp.setState({
								successMessage: null
							});
						}, 3000);
					} else {
						comp.setState({
							anredeError: (
								<small className='field-error animated rubberBand text-danger'>
									{result.fieldErrors['anrede']}
								</small>
							),
							vornameError: (
								<small className='field-error animated rubberBand text-danger'>
									{result.fieldErrors['vorname']}
								</small>
							),
							nachnameError: (
								<small className='field-error animated rubberBand text-danger left-padding'>
									{result.fieldErrors['nachname']}
								</small>
							),
							grundError: (
								<small className='field-error animated rubberBand text-danger'>
									{result.fieldErrors['grund']}
								</small>
							),
							emailError: (
								<small className='field-error animated rubberBand text-danger left-padding'>
									{result.fieldErrors['email']}
								</small>
							),
							landError: (
								<small className='field-error animated rubberBand text-danger left-padding'>
									{result.fieldErrors['land']}
								</small>
							),
							messageError: (
								<small className='field-error message-error animated rubberBand text-danger'>
									{result.fieldErrors['message']}
								</small>
							)
						});
					}
					comp.setState({
						submitBtnResponsive: true,
						contactFormOpacity: 1
					});
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					console.log(error);
				}
			);
	}

	resetForm() {
		this.setState({
			anredeError: '',
			vornameError: '',
			nachnameError: '',
			grundError: '',
			emailError: '',
			landError: '',
			messageError: '',
			confirmationError: '',
			checkboxChecked: false,
			checkboxError: false
		});

		this.vornameInput.current.value = '';
		this.nachnameInput.current.value = '';
		this.emailInput.current.value = '';
		this.landInput.current.value = '';
		this.handyInput.current.value = '';
		this.messageInput.current.value = '';
	}

	handleChange(event) {
		var inputName = event.target.name;
		var inputValue = event.target.value;

		this.setState({
			[inputName]: inputValue
		});
	}

	handleAnredeChange(option) {
		this.setState({
			anrede: option[0].value
		});
	}

	openMenu() {
		this.state.toggleMenuHandle();
	}

	toggleMenuHandle(handle) {
		this.setState({
			toggleMenuHandle: handle
		});
	}

	onCheckboxChange(state) {
		if (state) {
			this.setState({ checkboxError: false });
		}
		this.setState({
			checkboxChecked: state
		});
	}
}

export default Contact;
