import React from 'react';
import createReactClass from 'create-react-class';
import './HomeCalender.scss';

import Item from './Item.js';

import 'animate.css/animate.min.css';

import Swipe from 'react-easy-swipe';

var weekdays = ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'];

var HomeCalender = createReactClass({
	getInitialState: function () {
		this.days = React.createRef();
		return {
			items: this.getInitialItems(),
			itemWidth: this.props.smallScreen ? 6 : 7,
			position: 0,
			touchPositionX: 0,
			swipeStartX: 0
		};
	},

	render: function () {
		return (
			<React.Fragment>
				<Swipe
					onSwipeStart={this.onSwipeStart}
					onSwipeMove={this.onSwipeMove}
					onSwipeEnd={this.onSwipeEnd}
				>
					<div className='home-calender'>
						<div className='d-flex h-100'>
							<div className='d-flex justify-content-center flex-fill left'>
								<img
									src='./arrow.svg'
									className='arrow'
									onClick={this.goPrevious}
									alt='Arrow'
								></img>
							</div>
							<div className='wrapper'>
								<div
									className='days'
									style={{ left: -this.state.position * this.state.itemWidth + 'rem' }}
									ref={this.days}
								>
									{this.state.items}
								</div>
							</div>
							<div className='d-flex justify-content-center flex-fill right'>
								<img src='./arrow.svg' className='arrow' onClick={this.goNext} alt='Arrow'></img>
							</div>
							<div className='left-gradient d-lg-none'></div>
							<div className='right-gradient d-lg-none'></div>
						</div>
					</div>
				</Swipe>
			</React.Fragment>
		);
	},

	getItemWidth: function () {
		if (this.days.current === null) {
			return 0;
		} else {
		}
	},

	getInitialItems: function () {
		var currentDay = new Date();
		var numberOfItems = 365;
		var items = [];

		for (var i = 0; i < numberOfItems; i++) {
			items.push(
				<Item
					date={currentDay.getDate() + '.' + (currentDay.getMonth() + 1) + '.'}
					day={weekdays[currentDay.getDay()]}
					currentDate={
						currentDay.getFullYear() +
						'-' +
						('0' + (currentDay.getMonth() + 1)).slice(-2) +
						'-' +
						('0' + currentDay.getDate()).slice(-2)
					}
					key={i}
					setCurrentDate={this.props.setCurrentDate}
				/>
			);
			currentDay.setDate(currentDay.getDate() + 1);
		}

		return items;
	},

	goPrevious: function () {
		if (this.state.position > 0) {
			this.setState({
				position: this.state.position - (this.state.position > 3 ? 3 : this.state.position)
			});
		}
	},

	goNext: function () {
		if (this.state.position < 364) {
			this.setState({
				position: this.state.position + (this.state.position <= 360 ? 3 : this.state.position)
			});
		}
	},

	onSwipeStart: function (event) {
		this.setState({
			swipeStartX: this.state.touchPositionX
		});
	},

	onSwipeMove: function (position, event) {
		this.setState({
			touchPositionX: position.x
		});
	},

	onSwipeEnd: function (event) {
		var endPositionX = this.state.touchPositionX;

		if (Math.abs(endPositionX - this.state.swipeStartX) > 30) {
			if (endPositionX < this.state.swipeStartX) {
				this.goNext();
			} else if (endPositionX > this.state.swipeStartX) {
				this.goPrevious();
			}
		}

		this.setState({
			swipeStartX: 0,
			touchPositionX: 0
		});
	}
});

export default HomeCalender;
