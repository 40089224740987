import React from 'react';
import './Navbar.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Menu from './Menu.jsx';

class Navbar extends React.Component {
	constructor(props) {
		super(props);
		this.nav = React.createRef();
		this.toggleMenu = this.toggleMenu.bind(this);

		this.state = {
			topPosition: this.props.startingTop,
			menuOpen: false,
			prevScrollPos: window.pageYOffset,
			shadowVisible: false,
			buttonDisabled: false,
			listensToScroll: false,
			mainNavHeight: 0
		};
	}

	render() {
		var navClasses = classNames({
			'd-flex': true,
			'align-items-end': true,
			'app-navbar': true,
			'justify-content-between': true,
			'menu-open': this.state.menuOpen,
			'shadow-visible': this.state.shadowVisible
		});

		var logoClasses = classNames({
			'd-flex': true,
			'align-items-end': true
		});

		var fbIconStyle = {
			width: '27px',
			height: '27px',
			color: '#1D0A96',
			marginRight: '10px',
			cursor: 'pointer'
		};

		var ingIconStyle = {
			width: '27px',
			height: '27px',
			color: '#1D0A96',
			marginRight: '15px',
			cursor: 'pointer'
		};

		return (
			<React.Fragment>
				<div
					className={navClasses}
					style={{ top: window.pageYOffset < 50 ? 0 : this.state.topPosition }}
					ref={this.nav}
				>
					<a className={logoClasses} href='/'>
						<span className='logo'></span>
					</a>
					<div className='d-flex flex-row align-items-start menu-buttons'>
						<a
							href='https://www.facebook.com/marstallamsee'
							target='_blank'
							rel='noopener noreferrer'
						>
							<FontAwesomeIcon className='nav-icon-hover' style={fbIconStyle} icon={faFacebook} />
						</a>
						<a
							href='https://www.instagram.com/marstall_am_see'
							target='_blank'
							rel='noopener noreferrer'
						>
							<FontAwesomeIcon className='nav-icon-hover' style={ingIconStyle} icon={faInstagram} />
						</a>
						<FontAwesomeIcon icon={faBars} className='menu-burger' onClick={this.toggleMenu} />
					</div>
				</div>
				{this.state.menuOpen && <Menu closeMenu={this.toggleMenu} />}
			</React.Fragment>
		);
	}

	resolveNormalAnimation() {
		return Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 992
			? 'grow'
			: 'growBig';
	}

	resolveReverseAnimation() {
		return Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 992
			? 'shrink'
			: 'shrinkBig';
	}

	handleScroll = () => {
		if (this.state.listensToScroll) {
			const curScrollPos = window.pageYOffset;
			const scrollUp = this.state.prevScrollPos < curScrollPos;
			this.setState({
				topPosition: scrollUp || (curScrollPos === 0 && this.props.topHidden) ? '-8rem' : 0,
				prevScrollPos: curScrollPos,
				shadowVisible: !(window.pageYOffset === 0)
			});
		}
	};

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll);

		if (this.props.toggleMenuHandle) {
			this.props.toggleMenuHandle(this.toggleMenu);
		}

		var comp = this;
		setTimeout(function () {
			comp.setState({
				listensToScroll: true
			});
		}, 100);
		this.setState({
			mainNavHeight: this.nav.current.clientHeight
		});
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll);
	}

	toggleMenu() {
		this.setState({
			menuOpen: !this.state.menuOpen
		});
	}
}

export default Navbar;
