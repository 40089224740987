import React from 'react';

import './About.scss';
import Button from '../../components/Button.jsx';

class About extends React.Component {
	render() {
		return (
			<div className='about d-flex flex-column'>
				<span className='Marstall-am-See-l-Be'>Marstall am See l Berg am Starnberger See</span>
				<span className='Feiern-Sie-im-ehemal'>
					Feiern Sie im ehemaligen Pferdestall Ludwig II. Das historische Gebäude ist nicht nur ein
					wertvolles Kulturgut, sondern auch eine wunderbare Location für Feste und Veranstaltungen
					aller Art.
					<br />
					<br />
					Ob Hochzeit, Jubiläum, Firmenfeier oder Geburtstagsparty - Ihre Feier in dieser
					Eventlocation am Starnberger See wird zu einem besonderen Ereignis. Die Location kann nach
					individuellen Wünschen für Ihr Event ausgestaltet werden. Hier können Sie Ihr Seminar oder
					Ihren Workshop in heller und offener Atmosphäre durchführen oder Ihre eigene Messe
					kreieren. Ein idealer Ort für Mode- und Werbeaufnahmen und großräumige Location für
					Filmaufnahmen und Werbespots. Der Saal wurde auch bereits für eine Vielzahl von
					Kunstausstellungen, für Konzerte, Lesungen, Theater- oder Filmaufführungen genutzt. 340 m²
					stehen bereit für Ihre Ideen und Produkte direkt am Starnberger See – 30 Kilometer von
					München.
				</span>
				<div className='buttonWrapper'>
					<Button text='Mehr Infos' url='/about' />
				</div>
			</div>
		);
	}
}

export default About;
