import React from 'react';
import "./DefaultBtn.scss"
import createReactClass from 'create-react-class';

var DefaultBtn = createReactClass({
	render: function(){
		return(
			<div className={"termin-btn" + (this.props.centerContent ? " d-flex justify-content-" + this.props.centerContent : "")} onClick={this.props.onClick}>
				<div className="wrapper">
					<div className="circle" style={{border: '1px solid ' + this.props.btnColor}}></div>
					<span className="line" style={{borderBottom: '1px solid ' + this.props.btnColor}}></span>
				</div>
				<div className="termin-btn-text" style={{color: this.props.btnColor}}>{this.props.text}</div>
			</div>
		);
	}
});

export default DefaultBtn;