import React from 'react';

import styles from './FilterButtons.module.scss';

const CitiesFilter = ({ value, onChange, options }) => {
	return (
		<div className={styles['filter-buttons-container']}>
			{(options || []).map((option, index) => (
				<button
					key={`filter-button-${index}`}
					className={styles[`filter-button${option.value === value.value ? '-selected' : ''}`]}
					onClick={() => onChange(option)}
				>
					{option.label}
				</button>
			))}
		</div>
	);
};

export default CitiesFilter;
