import React from 'react';
import './Impressum.css';

import Navbar from '../navbar/Navbar.jsx';
import createReactClass from 'create-react-class';

var Impressum = createReactClass({
	render: function(){
		return(
			<div className="impressum">
				<Navbar/>
				<div className="impressum-title">IMPRESSUM</div>
				<div className="impressum-address side-padding app-font">
					<strong>MARSTALL AM SEE</strong><br/>Erich Höhne<br/>Mühlgasse 7<br/>82335 Berg<br/>Deutschland
				</div>
				<div className="impressum-contact side-padding app-font">
					Mobil +49 (0)171 3371444<br/>mail@marstall-am-see.de<br/>www.marstall-am-see.de
				</div>
			</div>
		);
	},

	openMenu: function(){
		this.state.toggleMenuHandle();
	},

	toggleMenuHandle(handle){
		this.setState({
			toggleMenuHandle: handle
		});
	}
});

export default Impressum;