import React from 'react';
import createReactClass from 'create-react-class';

import './InfoTopic.scss';

var InfoTopic = createReactClass({
	getInitialState: function(){
		this.self = React.createRef();
		return null;
	},

	render: function(){
		return(
			<div className="info-topic" onClick={() => this.props.click(this.props.title, this.self)} ref={this.self}>
				<span style={{fontWeight: (this.props.currentTopic === this.props.title) ? "900" : "300"}}>{this.props.title}</span>
			</div>
		);
	},

	componentDidMount(){
		if(this.props.callAfterMount){
			this.props.callAfterMount(this.self);
		}
	}
});

export default InfoTopic;