import React from 'react';

import './Album.scss';

import AlbumItem from './AlbumItem.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Swipe from 'react-easy-swipe';

class Album extends React.Component {
	constructor(props) {
		super(props);
		this.goLeft = this.goLeft.bind(this);
		this.goRight = this.goRight.bind(this);
		this.onSwipeStart = this.onSwipeStart.bind(this);
		this.onSwipeMove = this.onSwipeMove.bind(this);
		this.onSwipeEnd = this.onSwipeEnd.bind(this);

		this.state = {
			currentIndex: 0,
			touchPositionX: 0,
			swipeStartX: 0
		};
	}

	render(){

		var screenWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

		return(
			<div className="album d-flex flex-column">

				{this.props.smallScreen &&
					<Swipe
				        onSwipeStart={this.onSwipeStart}
				        onSwipeMove={this.onSwipeMove}
				        onSwipeEnd={this.onSwipeEnd}>
						<div className="album-items d-flex flex-row" style={{transform: "translate(-" + (this.state.currentIndex * screenWidth) + "px)"}}>
							{this.renderItems()}
						</div>
			        </Swipe>
				}
				{!this.props.smallScreen &&
					<div className="album-items d-flex flex-row" style={{transform: "translate(-" + (this.state.currentIndex * 1085) + "px)"}}>
						{this.renderItems()}
					</div>
				}

				<div className="album-footer d-flex flex-row">
					<div className="album-title">
						{this.props.albumData.title}
					</div>

					<div className="album-navigation d-flex flex-row justify-content-between">
						<div className="album-left d-flex align-items-center justify-content-center" onClick={this.goLeft}>
							<FontAwesomeIcon icon={faArrowLeft} className="album-icon"/>
						</div>
						<div className="album-right d-flex align-items-center justify-content-center" onClick={this.goRight}>
							<FontAwesomeIcon icon={faArrowRight} className="album-icon"/>
						</div>
					</div>
				</div>
			
			</div>
		);
	}


	renderItems() {
		var comp = this;
		if (this.props.albumData != null) {
			var renderedItems = [];
			comp.props.albumData.pictureNames.forEach(function(pictureName, index){
				renderedItems.push(
					<AlbumItem
						smallScreen={comp.props.smallScreen}
						pictureName={pictureName}
						albumId={comp.props.albumData.id}
						key={index}
						position={index}
						albumSize={comp.props.albumData.pictureNames.length}
					/>
				)
			});
			return renderedItems;
		}
	}

	goLeft() {
		var comp = this;
		if(comp.state.currentIndex > 0) {
			comp.setState({
				currentIndex: (comp.state.currentIndex - 1)
			});
		}
	}

	goRight() {
		var comp = this;
		if(comp.state.currentIndex < (comp.props.albumData.pictureNames.length - 1)) {
			comp.setState({
				currentIndex: comp.state.currentIndex + 1
			});
		}
	}


	onSwipeStart(event) {
		this.setState({
			swipeStartX: this.state.touchPositionX
		});
	}

	onSwipeMove(position, event) {
		this.setState({
			touchPositionX: position.x
		});
	}

	onSwipeEnd(event) {
		var endPositionX = this.state.touchPositionX;

		if((Math.abs(endPositionX - this.state.swipeStartX)) > 30){
			if((endPositionX < this.state.swipeStartX)){
				this.goRight();

			}else if(endPositionX > this.state.swipeStartX){
				this.goLeft();
			}
		}

		this.setState({
			swipeStartX: 0,
			touchPositionX: 0
		});
	}
}


export default Album;
