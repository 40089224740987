import React from 'react';
import './Home.scss';

import Navbar from '../navbar/Navbar.jsx';
import HomeCalender from './homeCalender/HomeCalender.jsx';
import Landing from './landing/Landing.jsx';
import Events from './events/Events.jsx';
import Art from './art/Art.jsx';
import About from './about/About.jsx';
import Partner from './partner/Partner.jsx';

class Home extends React.Component {
	render() {
		return (
			<div className='home'>
				<Navbar />
				<Landing />
				<HomeCalender
					smallScreen={this.props.smallScreen}
					setCurrentDate={this.props.setCurrentDate}
				/>
				<Events smallScreen={this.props.smallScreen} />
				<Art />
				<About />
				<Partner />
			</div>
		);
	}

	componentDidMount() {}
}

export default Home;
